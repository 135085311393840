import {subscribe} from "klaviyo-subscribe";
import {useEffect, useState} from "react";
import useService from "./useService";
import {useTranslation} from "react-i18next";

const initialMessage = {
  status: null,
  content: "",
};

export default listId => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState(initialMessage);
  const [sentRequest, setSentRequest] = useState(false);

  const {t} = useTranslation();

  const SUCCESS_MSG = t("newsletter.success");
  const ERROR_MSG = t("newsletter.error");

  const {loading, serviceCall: onSubmit} = useService(async () => {
    try {
      setMessage({
        status: "loading",
        content: "Loading...",
      });

      setSentRequest(true);

      const resp = await subscribe(listId, email, {
        $fields: ["newsletter_signup_page"],
        newsletter_signup_page: document.location
      });

      if (!resp.success) {
        throw new Error(resp);
      }

      setMessage({
        status: "success",
        content: SUCCESS_MSG,
      });
      return resp;
    } catch (e) {
      setMessage({
        status: "error",
        content: ERROR_MSG,
      });

      throw e;
    }
  });

  useEffect(() => {
    let timeout = null;

    if (message.status) {
      timeout = setTimeout(() => {
        setMessage(initialMessage);
        setEmail("");
        setSentRequest(false);
      }, 3000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [message]);

  return {loading, message, onSubmit, email, setEmail, sentRequest};
};
